import { useState  } from "react"

import Header from "./components/Header";
import Tasks from "./components/Tasks";
import AddTask from "./components/AddTask";

console.log('--------------******------------------');
console.log(process.env);
console.log(process.env);
console.log(process.env);
console.log('--------------******------------------');


function App() {
  const [showAddTask, setShowAddTask] = useState(false);
  const [tasks, setTasks] = useState([
    {
        id: 1,
        text: 'Prep breakfast for Tom',
        day: 'Today',
        reminder: true
    },
    {
        id: 2,
        text: 'Take Tom to Sarah\'s',
        day: 'Everyday',
        reminder: true
    },
    {
        id: 3,
        text: 'Pick up Tom from Sarah\'s',
        day: 'Everyday',
        reminder: true
    }])

    const addTask = (task) => {
      const id = Math.floor(Math.random() * 1000) + 1;
      const newTask = { id, ...task}
      setTasks([...tasks, newTask]);
    }

    const deleteTask = (id) => {
      setTasks(tasks.filter((task)=>task.id !== id))
    }

    const toggleReminder = (id) => {
      setTasks(
        tasks.map((task) => 
        task.id === id ? 
        {...task, reminder: !task.reminder} : 
        task
        )
      )
    }

  return (
    <div className="container">
      
      <Header 
        showAdd={showAddTask}
      onAdd={() => setShowAddTask(!showAddTask)} title="Task Tracker"/>
      
      {/* show or dont show add task form */}
      {showAddTask && <AddTask onAdd={addTask} />}
      
      { tasks.length > 0 ?
        (<Tasks tasks={tasks} 
          toggleReminder={toggleReminder} 
          onDelete={deleteTask} />) :
        (<p>No Tasks to Show</p>)
      }
    </div>
  );
}

export default App;
